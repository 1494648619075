export interface IFeature {
    name: string;
    defaultValue: boolean | string | number;
    frontend?: boolean;
    portalShell?: boolean;
}

export const FeaturesDefinitions = {
    /**
   * Service feature flags
   */
    EnableServiceAifabric: {
        name: 'enable-service-aifabric',
        defaultValue: true,
        frontend: true,
    },

    EnableServiceAcademy: {
        name: 'enable-service-academy',
        defaultValue: false,
        frontend: true,
    },

    EnableServiceDocs: {
        name: 'enable-service-docs',
        defaultValue: false,
        frontend: true,
    },

    EnableServiceCustomerPortal: {
        name: 'enable-service-customerportal',
        defaultValue: false,
        frontend: true,
    },

    EnableServiceOrchestrator: {
        name: 'enable-service-orchestrator',
        defaultValue: false,
        frontend: true,
    },

    EnableServiceChatbots: {
        name: 'enable-service-chatbots',
        defaultValue: false,
        frontend: true,
    },

    EnableServiceTestmanager: {
        name: 'enable-service-testmanager',
        defaultValue: false,
        frontend: true,
    },

    EnableServiceInsights: {
        name: 'enable-service-insights',
        defaultValue: false,
        frontend: true,
    },

    EnableServiceFpst: {
        name: 'enable-service-fpst',
        defaultValue: false,
        frontend: true,
    },

    /** Enables external user licensing */
    EnableExternalUserLicense: {
        name: 'EnableExternalUserLicense',
        defaultValue: false,
        frontend: true,
    },

    // Required for testing, do not remove
    EnableEnforceTestConnectionforAAD: {
        name: 'enableEnforceTestConnectionforAAD',
        defaultValue: true,
        frontend: true,
    },

    EnableSmartlingIntegration: {
        name: 'EnableSmartlingIntegration',
        defaultValue: false,
        frontend: true,
    },

    /** Support for Toggling Feature Flags in LocalStorage */
    // Required for testing, do not remove
    EnableLocalStorageFeatureFlags: {
        name: 'EnableLocalStorageFeatureFlags',
        defaultValue: false,
        frontend: true,
    },

    EnableRecreateDefaultTenant: {
        name: 'enableRecreateDefaultTenant',
        defaultValue: false,
        frontend: true,
    },

    /** Enable show benefits panel in request enterprise form */
    EnableShowEnterpriseBenefitsPanel: {
        name: 'enableShowEnterpriseBenefitsPanel',
        defaultValue: false,
        frontend: true,
    },

    /** Enable Ecommerce integration */
    EnableEcommerceIntegration: {
        name: 'enableEcommerceIntegration',
        defaultValue: true,
        frontend: true,
    },

    /** Enable Ecommerce in US */
    EnableEcommerceInUs: {
        name: 'enableEcommerceInUs',
        defaultValue: true,
        frontend: true,
    },

    /** Enable Ecommerce in Europe */
    EnableEcommerceInEurope: {
        name: 'enableEcommerceInEurope',
        defaultValue: true,
        frontend: true,
    },

    /** Enable Ecommerce in World */
    EnableEcommerceInWorld: {
        name: 'enableEcommerceInWorld',
        defaultValue: true,
        frontend: true,
    },

    /** Enable Ecommerce in Japan */
    EnableEcommerceInJapan: {
        name: 'enableEcommerceInJapan',
        defaultValue: true,
        frontend: true,
        portalShell: true,
    },

    /** Enable Ecommerce - Promotional discounts */
    EnableEcommercePromotionalDiscounts: {
        name: 'enableEcommercePromotionalDiscounts',
        defaultValue: true,
        frontend: true,
    },

    /** Enabled Ecommerce - Remove disableAutoFocus, disableEnforceFocus, disableRestoreFocus
     * from checkout component form as part of PLT-17057 */
    EnableEcommerceRemoveFocusProps: {
        name: 'enableEcommerceRemoveFocusProps',
        defaultValue: false,
        frontend: true,
    },

    /** Enabled Ecommerce -  */
    EnableEcommercePlanSelectionRevampM0: {
        name: 'enableEcommercePlanSelectionRevampM0',
        defaultValue: true,
        frontend: true,
        portalShell: true,
    },

    EnableAbTestingSupport: {
        name: 'enableAbTestingSupport',
        defaultValue: true,
        frontend: true,
        portalShell: true,
    },

    /** Enabled Ecommerce - Cart abandonment PLT-39634 */
    EnableEcommerceCartAbandonmentCampaign: {
        name: 'enableEcommerceCartAbandonmentCampaign',
        defaultValue: true,
        frontend: true,
    },

    /** Enabled Ecommerce - Current Cycle Prices PLT-41446 */
    EnableEcommerceCurrentCyclePrices: {
        name: 'enableEcommerceCurrentCyclePrices',
        defaultValue: true,
        frontend: true,
    },

    /** Enabled Ecommerce - Annual Robot Units PLT-54100 */
    enableEcommerceAnnualRobotUnits: {
        name: 'enableEcommerceAnnualRobotUnits',
        defaultValue: true,
        frontend: true,
    },

    EnableGovernanceModuleFederation: {
        name: 'enableGovernanceModuleFederation',
        defaultValue: false,
        frontend: true,
    },

    EnableAiTrustLayerContextGrounding: {
        name: 'EnableAiTrustLayerContextGrounding',
        defaultValue: false,
        frontend: true,
    },

    EnableAiTrustLayerAudit: {
        name: 'EnableAiTrustLayerAudit',
        defaultValue: false,
        frontend: true,
    },

    EnableAiTrustLayerUsage: {
        name: 'EnableAiTrustLayerUsage',
        defaultValue: false,
        frontend: true,
    },

    EnableAiTrustLayerA4E: {
        name: 'EnableAiTrustLayerA4E',
        defaultValue: false,
        frontend: true,
    },

    EnableA4EAnthropicCheck: {
        name: 'EnableA4EAnthropicCheck',
        defaultValue: true,
        frontend: true,
    },

    EnableA4EInsightsIntegration: {
        name: 'EnableA4EInsightsIntegration',
        defaultValue: false,
        frontend: true,
    },

    /** Enabled Ecommerce - Billing Address Validation - Address Autocomplete */
    EnableEcommerceAddressBillingValidation: {
        name: 'enableEcommerceAddressBillingValidation',
        defaultValue: true,
        frontend: true,
    },

    /** Enabled Ecommerce - Annual Renewal Decrease - Allow decrease */
    EnableEcommerceAnnualRenewalDecrease: {
        name: 'enableEcommerceAnnualRenewalDecrease',
        defaultValue: false,
        frontend: true,
    },
    /** Enable Ecommerce - Better Purchase Experience - Can reach public pages */
    EnableEcommerceBetterPurchaseExpM0: {
        name: 'enableEcommerceBetterPurchaseExpM0',
        defaultValue: false,
        frontend: true,
    },

    /** Enables the new Tenant API */
    EnableGetTenantsRedesign: {
        name: 'EnableGetTenantsRedesign',
        defaultValue: false,
        frontend: true,
    },

    /** Enable using packages from CDN */
    EnableCdnPackage: {
        name: 'enable-cdn-package',
        defaultValue: false,
    },

    /** Enable using backup CDN for apollo-packages */
    EnableApolloBackupCdn: {
        name: 'enable-apollo-backup-cdn',
        defaultValue: false,
    },

    /** Enable per cloud env CDN for apollo-packages */
    EnableApolloPerEnvCdn: {
        name: 'enable-apollo-per-env-cdn',
        defaultValue: false,
    },

    /** Portal-shell package version */
    /* You MUST include the hash at the end for it to work */
    PortalShellVersion: {
        name: 'portal-shell-version',
        defaultValue: '3.153.12',
    },

    /** Apollo-fonts package version */
    ApolloFontsVersion: {
        name: 'apollo-fonts-version',
        defaultValue: '1.25.1',
    },

    /* Enable Airgapped license activation for gov */
    EnableAirgappedLicenseActivation: {
        name: 'GovCloudOperation.UseAirgappedLicenseActivation',
        defaultValue: false,
        frontend: true,
    },

    // False in all envs
    ShowServerllessControlPlane: {
        name: 'show-serverlesscontrolplane',
        defaultValue: false,
        frontend: true,
    },

    /** Disable features for gov cloud */
    DisableFeatureFedRamp: {
        name: 'disable-feature-fed-ramp',
        defaultValue: false,
        frontend: true,
    },

    /** Enable notification settings page */
    EnableNotificationSettings: {
        name: 'enableNotificationSettings',
        defaultValue: false,
        frontend: true,
        portalShell: true,
    },

    /** Enable slack notification mode */
    EnableSlackNotification: {
        name: 'EnableSlackNotification',
        defaultValue: false,
        frontend: true,
        portalShell: true,
    },

    EnableTeamsNotification: {
        name: 'EnableTeamsNotification',
        defaultValue: false,
        frontend: true,
        portalShell: true,
    },

    EnableNotificationSeverityFilter: {
        name: 'enableNotificationSeverityFilter',
        defaultValue: false,
        frontend: true,
    },

    // Testing only
    /** Enable notification settings page */
    EnableNotificationProxy: {
        name: 'apollo-enableNotificationProxy',
        defaultValue: false,
        portalShell: true,
    },

    /** Enable notification settings page title with Preview Suffix */
    DisableNotificationPreview: {
        name: 'disableNotificationPreview',
        defaultValue: false,
        frontend: true,
        portalShell: true,
    },

    /** Enable notification popout page icon */
    EnableNotificationsPopoutPage: {
        name: 'enableNotificationsPopoutPage',
        defaultValue: false,
        frontend: true,
        portalShell: true,
    },

    /** Enables showing the usage charts and allocation details for Robot Units */
    EnableRobotUnits: {
        name: 'EnableRobotUnits',
        defaultValue: false,
        frontend: true,
    },

    EnableRobotDisplayName: {
        name: 'EnableRobotDisplayName',
        defaultValue: false,
        frontend: true,
    },

    /** Enable the fake language to show localization keys */
    EnableLocKeysLanguage: {
        name: 'enable-loc-keys-language',
        defaultValue: false,
        frontend: true,
        portalShell: true,
    },

    EnabledScheduledOrgMigration: {
        name: 'enabledScheduledOrgMigration',
        defaultValue: false,
        frontend: true,
    },

    EnabledScheduledTenantMigration: {
        name: 'enabledScheduledTenantMigration',
        defaultValue: false,
        frontend: true,
    },

    EnableReferenceTokens: {
        name: 'EnableReferenceTokens',
        defaultValue: false,
        frontend: true,
    },

    EnableRevokePATByValue: {
        name: 'EnableRevokePATByValue',
        defaultValue: false,
        frontend: true,
    },

    EnableHomePageCarousel: {
        name: 'EnableHomePageCarousel',
        defaultValue: false,
        frontend: true,
    },

    EnableTemplateWidget: {
        name: 'EnableTemplateWidget',
        defaultValue: false,
        frontend: true,
    },

    EnableTemplateWidgetForEnterprise: {
        name: 'EnableTemplateWidgetForEnterprise',
        defaultValue: false,
        frontend: true,
    },

    EnableLicenseInheritance: {
        name: 'EnableLicenseInheritance',
        defaultValue: false,
    },

    EnableContentfulSideBar: {
        name: 'EnableContentfulSideBar',
        defaultValue: false,
        frontend: true,
    },

    EnableStudioWebSideBar: {
        name: 'EnableStudioWebSideBar',
        defaultValue: false,
        frontend: true,
    },

    /** Allows the server to query Contentful for items in draft state. This should only ever be enabled in non-production environments. */
    EnableContentfulPreviewContent: {
        name: 'EnableContentfulPreviewContent',
        defaultValue: false,
        frontend: false,
    },

    ExpressRouteEnabled: {
        name: 'expressRouteEnabled',
        defaultValue: false,
        frontend: true,
    },

    IPSecPolicyEnabled: {
        name: 'ipSecPolicyEnabled',
        defaultValue: false,
        frontend: true,
    },

    VpnGatewayCost: {
        name: 'vpnGatewayCost',
        defaultValue: '5000',
        frontend: true,
    },

    /** Enable Local storage overrides for env config fields */
    EnablePortalShellLocalStorageOverrides: {
        name: 'enable-portal-shell-localstorage-overrides',
        defaultValue: false,
        portalShell: true,
    },

    /** Enable using self auth in people picker */
    EnablePortalShellSelfAuthPeoplePicker: {
        name: 'enable-portal-shell-self-auth-people-picker',
        defaultValue: false,
        portalShell: true,
    },

    /** Enables insights as an unlicensed service */
    EnableInsightsIsNoLongerALicensedService: {
        name: 'insightsIsNoLongerALicensedService',
        defaultValue: false,
        frontend: true,
    },

    /** Enables task mining as an unlicensed service */
    EnableTaskminingIsNoLongerALicensedService: {
        name: 'taskminingIsNoLongerALicensedService',
        defaultValue: false,
        frontend: true,
    },

    EnableStudioWebScope: {
        name: 'EnableStudioWebScope',
        defaultValue: false,
        frontend: true,
    },

    EnableInsightsScope: {
        name: 'EnableInsightsScope',
        defaultValue: false,
        frontend: true,
    },

    EnableStudioToggleForEnterpriseAndPro: {
        name: 'EnableStudioToggleForEnterpriseAndPro',
        defaultValue: false,
        frontend: true,
    },

    EnableMarketplaceToggleForEnterprise: {
        name: 'EnableMarketplaceToggleForEnterprise',
        defaultValue: false,
        frontend: true,
    },

    EnableHideIntegrationService: {
        name: 'EnableHideIntegrationService',
        defaultValue: false,
        frontend: true,
    },

    /** Enables AiUnits allocation at tenant level */
    EnableAiuAllocationOnTenant: {
        name: 'EnableAiuAllocationOnTenant',
        defaultValue: true,
        frontend: true,
    },

    /** Enables Action Center navigation in Portal Tag Labels */
    EnablePortalTagActionCenterNavigation: {
        name: 'EnablePortalTagActionCenterNavigation',
        defaultValue: true,
        frontend: true,
    },

    EnableUpgradeDialogOffersRevamp: {
        name: 'EnableUpgradeDialogOffersRevamp',
        defaultValue: false,
        portalShell: true,
        frontend: true,
    },

    EnableOrchestratorDelete: {
        name: 'EnableOrchestratorDelete',
        defaultValue: false,
        frontend: true,
    },

    LogExportInGov: {
        name: 'LogExportInGov',
        defaultValue: false,
        frontend: true,
    },

    HomePageWidgetMap: {
        name: 'HomePageWidgetMap',
        // eslint-disable-next-line max-len
        defaultValue: '{"AUTOMATION_EXPRESS_USER":["FRE","Automations","Runs"],"CITIZEN_DEV":["FRE","RecommendationTemplates","Apps","Automations","Runs","Actions"],"DefaultFallBack":["FRE","RecommendationTemplates","Automations","Runs"],"ADMIN":["FRE","Tenants","RecommendationTemplates","Recommendation","LicenseAllocation","Automations","Runs","Actions"],"RPA_DEVELOPER":["FRE","RecommendationTemplates","Tenants","Recommendation","Automations","Runs","Actions"],"UNKNOWN":["RecommendationTemplates","Automations","Runs"]}',
        frontend: true,
    },

    /** Enables using 307 instead of 302 */
    EnableApolloPackagesRedirect307: {
        name: 'enableApolloPackagesRedirect307',
        defaultValue: false,
    },

    EnableInsightsDataMonitoring: {
        name: 'EnableInsightsDataMonitoring',
        defaultValue: false,
        frontend: true,
    },

    // Required for testing
    EnableAADTestConnectionBrowserPost: {
        name: 'EnableAADTestConnectionBrowserPost',
        defaultValue: false,
        frontend: true,
    },

    EnableInsightsExportConfig: {
        name: 'EnableInsightsExportConfig',
        defaultValue: '',
        frontend: true,
    },

    ShowPlatformManagementLicensingScopes: {
        name: 'showPlatformManagementLicensingScopes',
        defaultValue: true,
        frontend: true,
    },

    /** Hides the Apps UI customization toggle */
    HideAppsToggle: {
        name: 'HideAppsToggle',
        defaultValue: false,
        frontend: true,
    },

    /** Hides the Marketplace UI customization toggle */
    HideMarketplaceToggle: {
        name: 'HideMarketplaceToggle',
        defaultValue: false,
        frontend: true,
    },

    /** Hides the Studio UI customization toggle */
    HideStudioToggle: {
        name: 'HideStudioToggle',
        defaultValue: false,
        frontend: true,
    },

    EnableHomePageLocaleSplit: {
        name: 'EnableHomePageLocaleSplit',
        defaultValue: false,
        frontend: true,
    },

    EnableSRSScope: {
        name: 'EnableSRSScope',
        defaultValue: false,
        frontend: true,
    },

    EnableConnectionServiceUserScope: {
        name: 'EnableConnectionServiceUserScope',
        defaultValue: false,
        frontend: true,
    },

    EnableServiceConnections: {
        name: 'enable-service-connections',
        defaultValue: false,
        frontend: true,
    },

    EnableServiceConnectorBuilder: {
        name: 'enable-service-connectorbuilder',
        defaultValue: false,
        frontend: true,
    },

    EnableServiceProvisioning: {
        name: 'enable-service-provisioning',
        defaultValue: false,
        frontend: true,
    },

    EnableServiceElements: {
        name: 'enable-service-elements',
        defaultValue: false,
        frontend: true,
    },

    EnableSRSRealTimeEventsCollection: {
        name: 'EnableSRSRealTimeEventsCollection',
        defaultValue: false,
        frontend: true,
    },

    EnableSRSTemplatesRealTimeEventsCollection: {
        name: 'EnableSRSTemplatesRealTimeEventsCollection',
        defaultValue: false,
        frontend: true,
    },

    EnableSRSRedirectTemplateURL: {
        name: 'EnableSRSRedirectTemplateURL',
        defaultValue: false,
        frontend: true,
    },

    AuditServiceBackendId: {
        name: 'AuditServiceBackendId',
        defaultValue: '',
    },

    EnableCustomerManagedKeyEntitlement: {
        name: 'EnableCustomerManagedKeyEntitlement',
        defaultValue: false,
        frontend: true,
    },

    EnableCustomerManagedKeyErrorBanner: {
        name: 'EnableCustomerManagedKeyErrorBanner',
        defaultValue: false,
        frontend: true,
    },

    EnableRuFallbackConfiguration: {
        name: 'enableRuFallbackConfiguration',
        defaultValue: false,
        frontend: true,
    },

    EnableAiChatFeature: {
        name: 'enableAiChatFeature',
        defaultValue: false,
        frontend: true,
    },

    EnableHighContrastLightTheme: {
        name: 'apollo-enableHighContrastLightTheme',
        defaultValue: false,
        frontend: true,
        portalShell: true,
    },

    ManageUsersMapping: {
        name: 'ManageUsersMapping',
        defaultValue: JSON.stringify({
            'orchestrator': '/orchestrator_/users',
            'actions': '/orchestrator_/users',
            'processes': '/orchestrator_/users',
            'aifabric': '/aifabric_/ai-app/tenant-user-management',
            'automationhub': '/automationhub_/admin/manage-users',
            'automationstore': '/automationhub_/admin/manage-users',
            'taskmining': '/taskmining_/app/users',
            'processmining': '/processmining_/admin/assign-roles',
            'insights': 'insights_/manageaccess',
        }),
        frontend: true,
    },

    /** Enable the navigation settings page */
    EnableProfileNavigationSettings: {
        name: 'enableProfileNavigationSettings',
        defaultValue: false,
        frontend: true,
    },

    EnableLeftRailExperiment: {
        name: 'apollo-enableLeftRailExperiment',
        defaultValue: false,
        frontend: true,
    },

    EnableApiCallsUsage: {
        name: 'EnableApiCallsUsage',
        defaultValue: false,
        frontend: true,
    },

    EnableTransportUnitsUsage: {
        name: 'EnableTransportUnitsUsage',
        defaultValue: false,
        frontend: true,
    },

    EnableIntegrationsDelete: {
        name: 'EnableIntegrationsDelete',
        defaultValue: false,
        frontend: true,
    },

    /** Enable Attribute Mapping feature for Azure AD and SAML 2.0 */
    EnableAttributeMapping: {
        name: 'EnableAttributeMapping',
        defaultValue: false,
        frontend: true,
    },

    EnableTestManagerDelete: {
        name: 'EnableTestManagerDelete',
        defaultValue: false,
        frontend: true,
    },

    EnableGlobalAppRegistrations: {
        name: 'EnableGlobalAppRegistrations',
        defaultValue: false,
        frontend: true,
    },

    HideIntegrationServiceToggle: {
        name: 'HideIntegrationServiceToggle',
        defaultValue: false,
        frontend: true,
    },

    EnableAbTestingLoggedEvents: {
        name: 'enableAbTestingLoggedEvents',
        defaultValue: '[]',
        frontend: true,
        portalShell: true,
    },

    EnableConsumablesTab: {
        name: 'EnableConsumablesTab',
        defaultValue: true,
        frontend: true,
    },
    EnableShowAiuChartForProcessMining: {
        name: 'EnableShowAiuChartForProcessMining',
        defaultValue: false,
        frontend: true,
    },
    EnableShowAiuChartForIntegrationService: {
        name: 'EnableShowAiuChartForIntegrationService',
        defaultValue: false,
        frontend: true,
    },
    EnableUpgradeToTrialRevamp: {
        name: 'EnableUpgradeToTrialRevamp',
        defaultValue: false,
        frontend: true,
    },

    EnableApolloSwitchingOrgDialog: {
        name: 'apollo-enableSwitchingOrgDialog',
        defaultValue: false,
        frontend: true,
    },

    /** If enabled, user is removed from groups in multiple licensing calls, as opposed to a single one,  in the new implementation.
     *  Used to synchronize with LA deployments on Cloud. **/
    EnableLegacyRemoveUserFromGroups: {
        name: 'EnableLegacyRemoveUserFromGroups',
        defaultValue: false,
        frontend: false,
    },

    EnableSAMLUXRefactor: {
        name: 'EnableSAMLUXRefactor',
        defaultValue: false,
        frontend: true,
    },

    EnableTokenValidation: {
        name: 'EnableTokenValidation',
        defaultValue: true,
        frontend: false,
    },

    EnableAvailableServiceRequestSkip: {
        name: 'EnableAvailableServiceRequestSkip',
        defaultValue: false,
    },

    EnableUseGovLinks: {
        name: 'EnableUseGovLinks',
        defaultValue: false,
        frontend: true,
    },

    HideInsightsToggle: {
        name: 'HideInsightsToggle',
        defaultValue: false,
        frontend: true,
    },

    EnableHideAcademyToggle: {
        name: 'EnableHideAcademyToggle',
        defaultValue: false,
        frontend: true,
    },

    EnableHideCustomerPortalToggle: {
        name: 'EnableHideCustomerPortalToggle',
        defaultValue: false,
        frontend: true,
    },

    EnableHideAiCenterToggle: {
        name: 'EnableHideAiCenterToggle',
        defaultValue: false,
        frontend: true,
    },

    EnableHideAutomationHubToggle: {
        name: 'EnableHideAutomationHubToggle',
        defaultValue: false,
        frontend: true,
    },

    EnableHideCommunicationsMiningToggle: {
        name: 'EnableHideCommunicationsMiningToggle',
        defaultValue: false,
        frontend: true,
    },

    EnableHideProcessMiningToggle: {
        name: 'EnableHideProcessMiningToggle',
        defaultValue: false,
        frontend: true,
    },

    EnableInsightsForAll: {
        name: 'EnableInsightsForAll',
        defaultValue: false,
        frontend: true,
    },

    EnablePortalUberClient: {
        name: 'EnablePortalUberClient',
        defaultValue: false,
        frontend: true,
    },

    EnableAADClientCertificate: {
        name: 'EnableAADClientCertificate',
        defaultValue: false,
        frontend: true,
    },

    EnableSubmitIdea: {
        name: 'EnableSubmitIdea',
        defaultValue: false,
        frontend: true,
    },

    EnableTenantAdminControl: {
        name: 'EnableTenantAdminControl',
        defaultValue: false,
        frontend: true,
    },

    EnableProvisionWithEntitlementsInsights: {
        name: 'EnableProvisionWithEntitlementsInsights',
        defaultValue: false,
        frontend: true,
    },

    EnableProvisionWithEntitlementsAutomationHub: {
        name: 'EnableProvisionWithEntitlementsAutomationHub',
        defaultValue: false,
        frontend: true,
    },

    EnableProvisionWithEntitlementsProcessMining: {
        name: 'EnableProvisionWithEntitlementsProcessMining',
        defaultValue: false,
        frontend: true,
    },

    EnableProvisionWithEntitlementsTestManager: {
        name: 'EnableProvisionWithEntitlementsTestManager',
        defaultValue: false,
        frontend: true,
    },

    EnableUserLicensingMigration: {
        name: 'EnableUserLicensingMigration',
        defaultValue: false,
        frontend: true,
    },

    EnableSwitchOffUserLicensingAfterMigration: {
        name: 'EnableSwitchOffUserLicensingAfterMigration',
        defaultValue: false,
        frontend: true,
    },

    EnableRUIntervalMigration: {
        name: 'EnableRUIntervalMigration',
        defaultValue: false,
        frontend: true,
    },

    HideSAMLServiceCertificateUsage: {
        name: 'HideSAMLServiceCertificateUsage',
        defaultValue: false,
        frontend: true,
    },

    EnableUnifiedAuditExperience: {
        name: 'EnableUnifiedAuditExperience',
        defaultValue: false,
        frontend: true,
    },

    EnableUnifiedAuditExperienceToggle: {
        name: 'EnableUnifiedAuditExperienceToggle',
        defaultValue: false,
        frontend: true,
    },

    EnableKMSClientCertificate: {
        name: 'EnableKMSClientCertificate',
        defaultValue: false,
        frontend: true,
    },

    SamlMaximumProvisioningRules: {
        name: 'SamlMaximumProvisioningRules',
        defaultValue: 1000,
        frontend: true,
    },

    CorsAllowOptions: {
        name: 'CorsAllowOptions',
        defaultValue: '{"allowOrigins": ["*"],"allowDomains": []}',
    },

    // TODO: Remove this feature flag starting with sprint S150
    /** Enables showing the insights dashboard on the organization level */
    EnableInsightsDashboardLicensePage: {
        name: 'EnableInsightsDashboardLicensePage',
        defaultValue: false,
        frontend: true,
    },

    EnableAdminOrgConsent: {
        name: 'EnableAdminOrgConsent',
        defaultValue: false,
        frontend: true,
    },

    EnableSentry: {
        name: 'EnableSentry',
        defaultValue: false,
        frontend: true,
    },

    EnableSwitchAuthZToCentralizedAccess: {
        name: 'EnableSwitchAuthZToCentralizedAccess',
        defaultValue: false,
        frontend: true,
    },

    EnableCheckAccess: {
        name: 'EnableCheckAccess',
        defaultValue: false,
        frontend: true,
    },

    EnableOrchRoleAssignmentForAX: {
        name: 'EnableOrchRoleAssignmentForAX',
        defaultValue: false,
        frontend: true,
    },

    EnableServiceIsVisible: {
        name: 'EnableServiceIsVisible',
        defaultValue: false,
    },

    /** Controls UI for Dedicated environment */
    EnableOnDedicated: {
        name: 'EnableOnDedicated',
        defaultValue: false,
        frontend: true,
    },

    EnableSAMLAuthNToggle: {
        name: 'EnableSAMLAuthNToggle',
        defaultValue: false,
        frontend: true,
    },

    EnableIpRestrictionGridRefactor: {
        name: 'EnableIpRestrictionGridRefactor',
        defaultValue: false,
        frontend: true,
    },

    EnableAzureB2CAuthentication: {
        name: 'EnableAzureB2CAuthentication',
        defaultValue: false,
        frontend: true,
    },

    EnableAzureB2CAuthenticationDefaultIdp: {
        name: 'EnableAzureB2CAuthenticationDefaultIdp',
        defaultValue: false,
        frontend: true,
    },

    EnableAuthLogDebugger: {
        name: 'EnableAuthLogDebugger',
        defaultValue: false,
        frontend: true,
    },

    EnableFpsDocsUrl: {
        name: 'apollo-enableFpsDocsUrl',
        defaultValue: false,
        frontend: true,
        portalShell: true,
    },

    EnableNewAccountAndGroupsUX: {
        name: 'EnableNewAccountAndGroupsUX',
        defaultValue: false,
        frontend: true,
    },

    EnableNewAccountAndGroupsUXToggle: {
        name: 'EnableNewAccountAndGroupsUXToggle',
        defaultValue: false,
        frontend: true,
    },

    EnableAlertDuration: {
        name: 'apollo-enableAlertDuration',
        defaultValue: false,
        frontend: true,
        portalShell: true,
    },

    EnableToastManager: {
        name: 'EnableToastManager',
        defaultValue: false,
        frontend: true,
    },

    EnableAlwaysProvisionedServices: {
        name: 'EnableAlwaysProvisionedServices',
        defaultValue: false,
        frontend: true,
    },
};

export type IFeatures = {
    [K in keyof typeof FeaturesDefinitions]: IFeature;
};

export const Features = FeaturesDefinitions satisfies IFeatures;

export const DefaultValuesMap = Object.keys(Features).reduce((accumulator, current) => {
    const value = Features[current as keyof IFeatures];
    accumulator[value.name] = value.defaultValue;
    return accumulator;
}, {} as Record<string, boolean | string | number>);

export const FrontendFeatureFlags = Object.values(Features)
    .filter((featureInformation: IFeature) => !!featureInformation.frontend);

export const PortalShellFeatureFlags = Object.values(Features)
    .filter((featureInformation: IFeature) => !!featureInformation.portalShell);
